<template>
  <div v-if="init">
    <div>
      <div v-if="isUserOwner" class="invite-header">
        <h4 class="headline">
          {{ invite.targetEmail }}
          <hr />
        </h4>
        <!-- <small class="color-first" v-if="user.userId === invite.collaborationId"
          >( you )</small
        > -->
        <div class="invite-action">
          <button
            type="button"
            class="btn btn-sm btn-link mr-1"
            v-if="
              (invite.targetEmail === user.email &&
                user.role !== 'Professional') ||
                isUserOwner
            "
            @click="cancelInvitation(invite)"
            title="Cancel invitation"
          >
            Cancel invite
          </button>
        </div>
      </div>
      <div v-else-if="invite.targetEmail === user.email" class="invite-header">
        <h4 class="headline">
          {{ invite.sender }}
          <hr />
        </h4>
        <div class="invite-action">
          <button
            type="button"
            class="btn btn-sm btn-primary"
            v-if="
              invite.targetEmail === user.email && user.role !== 'Professional'
            "
            @click="acceptInvitation(invite)"
            title="Accept invitation"
          >
            Accept Invitation
          </button>
          <button
            type="button"
            class="btn btn-sm btn-link mr-1"
            v-if="
              invite.targetEmail === user.email && user.role !== 'Professional'
            "
            @click="declineInvitation(invite)"
            title="Cancel invitation"
          >
            Cancel invite
          </button>
        </div>
      </div>
    </div>
    <!-- <div class="erow row d-flex" v-if="isRegistered">
      <div class="col-1 align-self-center">
        <div class="align-self-center">
          <i class="fas fa-paper-plane"></i>
        </div>
      </div>
      <div class="col-3 align-self-center">
        <div>From</div>
        <div class="headline">{{ invite.sender }}</div>
      </div>
      <div class="col-3 align-self-center">
        <div>To</div>
        <div class="headline">{{ invite.targetEmail }}</div>
      </div>
      <div class="col-3 align-self-center">
        <div>Status</div>
        <div class="headline">Waiting on accept..</div>
      </div>
      
    </div> -->
    <div>
      <div class="invite-body">
        <h6><b>Invitation sent</b></h6>
        <p>
          To be able to publish your thesis topic, all members of the student
          group must sign up
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import collaborationService from '../services/collaborationService';
export default {
  name: "UserInvite",
  props: ["invite", "user", "isUserOwner", "id"],
  data() {
    return {
      isRegistered: false,
    };
  },
  created() {
    // this.init();
  },
  computed: {
    init() {
      var result = false;
      var email = this.invite.targetEmail;
      if (typeof email !== typeof undefined) {
        // this.getUser();
        result = true;
      }
      return result;
    },
  },
  methods: {
    cancelInvitation(invite) {
      const data = {
        collaborationId: invite.collaborationId,
        targetEmail: invite.targetEmail,
      };
      collaborationService.cancelThesisInvite(data).then(() => {
        this.$emit('refresh') // this is to update the data at the parent component level, easier but not as cleaner as vuex approach
      })
    },
    declineInvitation(invite) {
      const data = {
        collaborationId: invite.collaborationId,
        targetEmail: invite.targetEmail,
        type: 1
      };
      collaborationService.cancelThesisInvite(data).then(() => {
        this.$emit('refresh')
      })
      // // if(invite.invites.targetEmail == this.user.email){
      // const templateData = {
      //   toEmail: invite.targetEmail,
      //   param1: this.user.firstName,
      //   template: "Student - Declined invitation",
      // };
      // this.$store.dispatch("sendMail", templateData);
      // // }
      // // console.log(invite);
      // this.$store.dispatch("deleteCollaborationInviteById", data);
      // this.$store.dispatch(
      //   "getCollaborationInvitesByCollabId",
      //   this.$route.params.id
      // ); // deleteCollaborationInviteById does this now..
    },
    acceptInvitation(invite) {
      const data = {
        collaborationId: invite.collaborationId,
        targetEmail: invite.targetEmail,
      }
      collaborationService.acceptThesisInvitation(data).then(() => {
        this.$emit('refresh')
      })
      // this.$store.dispatch('acceptThesisInvitation', data).then(() => this.$store.dispatch('getCollaboration', this.$route.params.id))
      // this.$store.dispatch('acceptThesisInvitation', )
      // var vm = this;
      // var data = {
      //   collaborationId: invite.collaborationId,
      //   inviteId: this.id,
      //   interests: this.user.interests,
      // };
      // vm.$store.dispatch("acceptCollaborationInvitationFromHome", data);

      // //MAIL 'Student - New Student Member Joined'
      // const templateData = {
      //   toEmail: invite.senderEmail,
      //   firstName: vm.user.firstName,
      //   lastName: vm.user.lastName,
      //   param1: invite.title,
      //   param2: invite.collaborationId,
      //   template: "Student - New Student Member Joined",
      // };
      // vm.$store.dispatch("sendMail", templateData);

      // this.$store.dispatch('getCollaborationInvitesByCollabId', this.$route.params.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.profile p {
  color: #000000;
  // padding: 0 1rem;
  font-size: 14px;
}
.profile-content h6 {
  margin-bottom: 0.4rem;
}
.profile h6 {
  font-weight: 400;
  color: #333;
  margin: 0;
  font-size: 14px;
}
.invite-body h6 b {
  font-weight: 600;
  margin: 1rem 0 0;
  color: #000000;
}

.invite-body {
  color: #000000;
  padding: 0;
}

h4 {
  color: #000000;
}
button.btn.btn-sm.btn-link {
  color: #401663;
}
.invite-header {
  display: flex;
  flex-direction: row;
  align-content: flex-end;
  justify-content: space-between;
}

.invite-header h4 {
  width: 80%;
}

.invite-header .invite-action {
  text-align: center;
}
</style>
