<template>
  <div class="profile">
    <div class="row">
      <div class="col-12" style="display:flex">
        <h4 class="headline" v-if="isUserOwner || isUserOfferingViewingAproved">
          {{ profile.firstName }} {{ profile.lastName }}
          <small class="color-first" v-if="user.email === profile.email">( you )</small>
          <hr />
        </h4>
        <h4 v-else class="headline">Student #{{ index + 1 }}</h4>
        <div class="member-action">
          <button
            type="button"
            class="btn btn-sm btn-link mr-1 btn-remove"
            v-if="
              typeof remove != 'undefined' &&
                profile.email != user.email &&
                user.role !== 'Professional' &&
                isUserOwner
            "
            @click="removeMember(profile.email)"
            title="Cancel invitation"
          >
            Remove
          </button>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="student-profile">
        <div
          class="profile-skills"
          v-if="isCoreSkills || isHardSkills || isJobs"
        >
          <ul>
            <li
              class="profile-skill-prof"
              v-for="(skill, index) in profile.hardSkill"
              :key="1 + index"
              v-show="skill != ''"
            >
              {{ skill }}
            </li>
            <li
              class="profile-skill-core"
              v-for="(skill, index) in profile.coreSkill"
              :key="2 + index"
              v-show="skill != ''"
            >
              {{ skill }}
            </li>
            <li
              class="profile-skill-jobs"
              v-for="(job, index) in profile.jobs"
              :key="3 + index"
            >
              <template
                v-if="job.name.indexOf('All') != -1 && job.name.length == 3"
              >
                {{ job.catgeory_name }} ({{ job.name }})
              </template>
              <template v-else>
                {{ job.name }}
              </template>
            </li>
          </ul>
        </div>
        <div
          class="profile-content"
          v-if="!profile.passionAndMotivation && !profile.profileBio"
        >
          <div>
            <h6 class="mt-0">
              <b>Description</b>
            </h6>
            <p>
              The student has not added any additional information
            </p>
          </div>
        </div>
        <div class="profile-content toogle-this" v-else>
          <div
            v-if="profile.profileBio
            "
          >
            <h6>
              <b>Description</b>
            </h6>
            <p v-html="filterLineBreaks(profile.profileBio)"></p>
          </div>
          <div
            v-if="profile.passionAndMotivation"
          >
            <h6>
              <b>My passion</b>
            </h6>
            <p v-html="filterLineBreaks(profile.passionAndMotivation)"></p>
          </div>
          <div class="toggler" :key="2" v-show="isReadMore">
            <span class="readMore" v-on:click="toggle">Load More</span>
          </div>
          <div class="toggler" :key="3" v-show="isReadLess">
            <span class="readLess" v-on:click="toggle">Load Less</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Member",
  props: [
    "profile",
    "isUserOwner",
    "isUserOfferingViewingAproved",
    "user",
    "id",
    "index",
    "remove",
  ],
  data() {
    return {
      data: {
        collapsedHeight: 220,
        readMore: "Read More",
        readLess: "Read Less",
        speed: 200,
      },
      isReadMore: true,
      isReadLess: false,
    };
  },
  computed: {
    isCoreSkills: function() {
      if (!this.profile.coreSkill) return false;
      return typeof this.profile.coreSkill != "undefined"
      ? Object.keys(this.profile.coreSkill)?.length !== 0
      ? true
      : false
      : false;
    },
    isHardSkills: function() {
      if (!this.profile.hardSkill) return false;
      return typeof this.profile.hardSkill != "undefined"
        ? Object.keys(this.profile.hardSkill)?.length !== 0
          ? true
          : false
        : false;
    },
    isJobs: function() {
      // console.log("job", this.profile.jobs);
      if(!this.profile.jobs) return false;
      return typeof this.profile.jobs != "undefined"
        ? this.profile.jobs?.length != 0
          ? true
          : false
        : false;
    },
  },
  mounted() {
    this.scan_div();
  },
  methods: {
    scan_div() {
      let elem = document.querySelector(".toogle-this");
      // elems.forEach(e=>{
      if (elem.offsetHeight > this.data.collapsedHeight) {
        elem.style.height = this.data.collapsedHeight + "px";
        this.isReadMore = true;
      }
    },
    gen_elem(data, cl) {
      return '<span class="' + cl + '">' + data + "</span>";
    },
    filterLineBreaks(text) {
      return text?.replace(/\r?\n/g, "<br/>");
    },
    toggle() {
      let self = document.querySelector(".toogle-this");
      let ht = self.offsetHeight;
      if (this.isReadMore) {
        this.isReadLess = true;
        this.isReadMore = false;
        self.style.height = "auto";
      } else {
        this.isReadMore = true;
        this.isReadLess = false;
        self.style.height = this.data.collapsedHeight + "px";
      }
    },
    removeMember(member) {
      this.$emit("removeMember", member);
    },
  },
};
</script>

<style lang="scss" scoped>
.profile {
  padding: 1rem;
  background: #fff;
  box-shadow: 0 1px 4px rgb(0 0 0 / 15%);
  border-radius: 4px;
  margin-bottom: 2rem;

  .headline {
    width: 80%;
    color: #333;
  }
  .member-action {
    text-align: center;
    margin: 0 auto;
  }
  h2 {
    color: #333;
  }
  h6 {
    font-weight: 400;
    color: #333;
    margin: 1rem 1rem 0;
    font-size: 14px;
    b {
      font-weight: bold;
    }
  }
  p {
    color: #333;
    padding: 0 1rem;
    font-size: 14px;
  }
}
.profile-content h6 {
  margin-bottom: 0.4rem;
}
.input-group div {
  width: 100%;
}
.profile-skills ul li {
  // float: left;
  // list-style: none;
  // border: 1px solid #da518a;
  // padding: 0.2rem 0.5rem;
  // border-radius: 8px;
  // margin-right: 0.4rem;
  // margin-bottom: 0.4rem;
  // color: #da518a;
  // font-size: 12px;

  float: left;
  list-style: none;
  border: 1px solid #da518a;
  padding: 0.2rem 0.5rem;
  border-radius: 8px;
  margin-right: 0.4rem;
  margin-bottom: 0.4rem;
  color: #da518a;
  font-size: 14px;
}

.profile-skills ul {
  padding: 0.5rem 1rem 0rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.profile-content {
  clear: both;
}

.profile-skills ul li.profile-skill-core {
  border: 1px solid #4c2f71;
  color: #4c2f71;
}
.profile-skills ul li.profile-skill-jobs {
  border: 1px solid #4c9674;
  color: #4c9674;
}

.toogle-this {
  overflow: hidden;
  position: relative;
}
.toggler {
  text-align: center;
  padding: 1rem 0 0 0;
  cursor: pointer;
}
.toggler .readMore {
  width: 100%;
  position: absolute;
  bottom: 0px;
  right: 0;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgb(255, 255, 255));
  padding: 100px 40px 0px;
}
.btn-remove {
  float: right;
  color: #5A2871;
}
</style>
