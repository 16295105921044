var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"profile"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12",staticStyle:{"display":"flex"}},[(_vm.isUserOwner || _vm.isUserOfferingViewingAproved)?_c('h4',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.profile.firstName)+" "+_vm._s(_vm.profile.lastName)+" "),(_vm.user.email === _vm.profile.email)?_c('small',{staticClass:"color-first"},[_vm._v("( you )")]):_vm._e(),_c('hr')]):_c('h4',{staticClass:"headline"},[_vm._v("Student #"+_vm._s(_vm.index + 1))]),_c('div',{staticClass:"member-action"},[(
            typeof _vm.remove != 'undefined' &&
              _vm.profile.email != _vm.user.email &&
              _vm.user.role !== 'Professional' &&
              _vm.isUserOwner
          )?_c('button',{staticClass:"btn btn-sm btn-link mr-1 btn-remove",attrs:{"type":"button","title":"Cancel invitation"},on:{"click":function($event){return _vm.removeMember(_vm.profile.email)}}},[_vm._v(" Remove ")]):_vm._e()])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"student-profile"},[(_vm.isCoreSkills || _vm.isHardSkills || _vm.isJobs)?_c('div',{staticClass:"profile-skills"},[_c('ul',[_vm._l((_vm.profile.hardSkill),function(skill,index){return _c('li',{directives:[{name:"show",rawName:"v-show",value:(skill != ''),expression:"skill != ''"}],key:1 + index,staticClass:"profile-skill-prof"},[_vm._v(" "+_vm._s(skill)+" ")])}),_vm._l((_vm.profile.coreSkill),function(skill,index){return _c('li',{directives:[{name:"show",rawName:"v-show",value:(skill != ''),expression:"skill != ''"}],key:2 + index,staticClass:"profile-skill-core"},[_vm._v(" "+_vm._s(skill)+" ")])}),_vm._l((_vm.profile.jobs),function(job,index){return _c('li',{key:3 + index,staticClass:"profile-skill-jobs"},[(job.name.indexOf('All') != -1 && job.name.length == 3)?[_vm._v(" "+_vm._s(job.catgeory_name)+" ("+_vm._s(job.name)+") ")]:[_vm._v(" "+_vm._s(job.name)+" ")]],2)})],2)]):_vm._e(),(!_vm.profile.passionAndMotivation && !_vm.profile.profileBio)?_c('div',{staticClass:"profile-content"},[_vm._m(0)]):_c('div',{staticClass:"profile-content toogle-this"},[(_vm.profile.profileBio
          )?_c('div',[_vm._m(1),_c('p',{domProps:{"innerHTML":_vm._s(_vm.filterLineBreaks(_vm.profile.profileBio))}})]):_vm._e(),(_vm.profile.passionAndMotivation)?_c('div',[_vm._m(2),_c('p',{domProps:{"innerHTML":_vm._s(_vm.filterLineBreaks(_vm.profile.passionAndMotivation))}})]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isReadMore),expression:"isReadMore"}],key:2,staticClass:"toggler"},[_c('span',{staticClass:"readMore",on:{"click":_vm.toggle}},[_vm._v("Load More")])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isReadLess),expression:"isReadLess"}],key:3,staticClass:"toggler"},[_c('span',{staticClass:"readLess",on:{"click":_vm.toggle}},[_vm._v("Load Less")])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h6',{staticClass:"mt-0"},[_c('b',[_vm._v("Description")])]),_c('p',[_vm._v(" The student has not added any additional information ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h6',[_c('b',[_vm._v("Description")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h6',[_c('b',[_vm._v("My passion")])])
}]

export { render, staticRenderFns }